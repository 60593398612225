<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" destroy-on-close>
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="国家名称" prop="countryName" >
        <a-input v-model="form.countryName" placeholder="请输入内容"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="国旗" prop="countryImage" >
        <file-upload type="image" :defaultList="form.countryImageArr" :count="1"
                     @input="getImg($event, 'countryImage')"></file-upload>
        <span style="color: red">建议上传图片尺寸：215 x 130 px</span>

      </a-form-model-item>
      <a-form-model-item label="轮播图" prop="image" >
        <file-upload type="image" :defaultList="form.imageArr" :count="60"
                     @input="getImg($event, 'image')"></file-upload>
        <span style="color: red">建议上传图片尺寸：686 x 320 px</span>
      </a-form-model-item>
      <a-form-model-item label="攻略内容" prop="content" >
        <editor v-model="form.content" placeholder="请输入内容"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCountry, addCountry, updateCountry } from '@/api/country/country'
import {guid} from "@/utils/ruoyi";
import Editor from "@/components/Editor";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        countryName: null,
        countryImage: null,
        countryImageArr:[],
        countryImageCount:1,
        image: null,
        imageArr:[],
        imageCount:60,
        createTime: null,
        content:null,
        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        countryName: [
          { required: true, message: '国家名称不能为空', trigger: 'blur' }
        ],
        countryImage: [
          { required: true, message: '国旗不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '攻略内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        countryName: null,
        countryImage: null,
        countryImageArr:[],
        countryImageCount:60,
        createTime: null,
        content:null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },

    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCountry({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        let that = this
        this.form.imageArr = []
        this.form.countryImageArr=[]
        if(this.form.countryImage){
          this.form.countryImageArr.push({
            uid: guid(8, 10),
            name: that.form.countryImage,
            status: 'done',
            halfUrl: that.form.countryImage,
            url: that.form.countryImage,
            path: that.form.countryImage
          })
        }
        if(this.form.image){
          this.form.image.split(",").forEach(item=>{
            that.form.imageArr.push({
              uid: guid(8, 10),
              name: item,
              status: 'done',
              halfUrl: item,
              url: item,
              path: item
            })
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCountry(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCountry(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
